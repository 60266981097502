/* Buttons */
.btn,
.btn-sm {
    @apply inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors disabled:pointer-events-none disabled:opacity-50;
    @apply h-10 px-4 py-2;
}

.btn {
    @apply bg-secondary text-primary hover:bg-secondary/95;
    @apply px-8 py-3 shadow-lg;
}

.btn-sm {
    @apply px-4 py-2 shadow;
}

.btn-ghost {
    @apply text-secondary border border-bg-accent hover:bg-bg-accent/50;
}