@tailwind base;
@tailwind components;
@tailwind utilities;

@import './tailwind/buttons';

.material-symbols-rounded-filled {
  font-family: 'Material Symbols Rounded';
  font-weight: normal;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'liga';
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48;
}

.truncate-3-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bullet {
  width: 10px;
  height: 10px;
  background-color: transparent;
  border: 2px solid hsl(267, 91%, 65%); 
  border-radius: 50%;
  display: inline-block;
  flex-shrink: 0;
}

@layer base {
  :root {

    --text-primary: 0 0% 9%;
    --text-secondary: 0 0% 32%;
    --text-placeholder: 225 6% 75%;
    --text-on-color: 0 0% 100%;
    --text-brand: 267 91% 65%;

    --layer-01: 0 0% 96%;

    --button-primary: 220 4% 13%;
    --button-primary-hover: 220 4% 19%;
    --button-secondary: 0 0% 95%;
    --button-secondary-hover: 0 0% 90%;

    --primary: 267 91% 65%; // chronoly purple
    --secondary: 269 100% 92%; // chronoly purple light

    --accent: 267 91% 65%;
    --accent-light: 269 100% 92%;

    --bg: 0 0% 100%;
    --bg-section: 0 0% 98%;
    --bg-accent: 0 0% 88%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
  }
}

@layer utilities {
  .text-5xl {
    @apply text-[3.5rem] font-bold xl:text-[5.75rem];
  }
  .text-4xl {
    @apply text-[2.5rem] font-bold xl:text-[3.25rem];
  }
  .text-3xl {
    @apply text-[2rem] font-bold xl:text-[2.5rem];
  }
  .text-2xl {
    @apply text-[1.375rem] xl:text-[1.375rem];
  }
  .text-xl {
    @apply text-[1.125rem] xl:text-[1.25rem];
  }
  .text-lg {
    @apply text-[1rem] xl:text-[1.125rem];
  }
  .text-base {
    @apply xl:text-[1rem];
  }
  .text-sm {
    @apply text-[0.875rem];
  }
  .text-xs {
    @apply text-[0.75];
  }
}

